html, body {
  margin: 0;
  padding: 0; 
  width: 100%; 
  height: 100%;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000 !important;
}

.banner-font {
  font-family: 'Bad Script', cursive;
  color:rgb(245, 121, 59) !important;
}

.icon-color {
  color:rgb(245, 121, 59) !important;
}

.banner-color-button {
  border-color: rgb(245, 121, 59) !important;
  color:rgb(245, 121, 59) !important;
}

.card-border {
  border-color: rgb(245, 121, 59) !important;
}

.fit_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity:0.90;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper1 { 
  width: 100%; 
  height: 100%; 
  /* background: SkyBlue; */
}
